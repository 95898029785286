import '@/styles/globals.css'

import type {AppProps} from 'next/app'
import localFont from 'next/font/local'

const avenir = localFont({
    src: [
        {
            path: '../fonts/AvenirLTStd-Black.woff2',
            weight: '900',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-BlackOblique.woff2',
            weight: '900',
            style: 'italic',
        },
        {
            path: '../fonts/AvenirLTStd-Heavy.woff2',
            weight: '800',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-HeavyOblique.woff2',
            weight: '800',
            style: 'italic',
        },
        {
            path: '../fonts/AvenirLTStd-Medium.woff2',
            weight: '600',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-MediumOblique.woff2',
            weight: '600',
            style: 'italic',
        },
        {
            path: '../fonts/AvenirLTStd-Roman.woff2',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-Oblique.woff2',
            weight: '500',
            style: 'italic',
        },
        {
            path: '../fonts/AvenirLTStd-Book.woff2',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-BookOblique.woff2',
            weight: '400',
            style: 'italic',
        },
        {
            path: '../fonts/AvenirLTStd-Light.woff2',
            weight: '300',
            style: 'normal',
        },
        {
            path: '../fonts/AvenirLTStd-LightOblique.woff2',
            weight: '300',
            style: 'italic',
        },
    ],
    variable: '--font-avenir',
})

const presicav = localFont({
    src: [
        {
            path: '../fonts/PresicavRg-Regular.woff2',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../fonts/PresicavRg-Bold.woff2',
            weight: '700',
            style: 'normal',
        },
        {
            path: '../fonts/PresicavHv-Regular.woff2',
            weight: '900',
            style: 'normal',
        },
        {
            path: '../fonts/PresicavLt-Regular.woff2',
            weight: '300',
            style: 'normal',
        },
        {
            path: '../fonts/PresicavXl-Regular.woff2',
            weight: '200',
            style: 'normal',
        },
        {
            path: '../fonts/PresicavUl-Regular.woff2',
            weight: '100',
            style: 'normal',
        },
    ],
    variable: '--font-presicav',
})

export default function App({Component, pageProps}: AppProps) {
    return (
        <main className={`${avenir.variable} ${presicav.variable}`}>
            <Component {...pageProps} className="scroll-smooth" />
        </main>
    )
}
